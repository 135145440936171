import request from '@/common/axios';

// vip 配置列表
export function fetchVipList(data) {
    return request({
        url: '/web/admin/vip/level/page',
        method: 'POST',
        data: {
            ...data
        }
    });
}

// 添加vip配置
export function AddVipSetting(data) {
    return request({
        url: '/web/admin/vip/level/add',
        method: 'POST',
        data: {
            ...data
        }
    });
}

// 修改vip配置
export function updateVipSetting(data) {
    return request({
        url: '/web/admin/vip/level/update',
        method: 'POST',
        data: {
            ...data
        }
    });
}


// 删除vip配置
export function delVipSetting(data) {
    return request({
        url: '/web/admin/vip/level/delete',
        method: 'POST',
        data: {
            ...data
        }
    });
}