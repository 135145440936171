<template>
  <div>
    <el-row :gutter="20" class="search-box"> </el-row>
    <div>
      <el-button
        class="but"
        type="primary"
        @click="showAdd"
        v-auth="this.per.DEPOSIT_ADD"
        >添加vip配置</el-button
      >
    </div>
    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column
        label="名称"
        prop="name"
      >
      </el-table-column>

      <el-table-column label="优惠金额" prop="amount" align="right">
        <template #default="scope">
          <span>{{ scope.row.amount / 100 }}</span>
        </template>
      </el-table-column>

      <el-table-column label="积分区间" prop="score">
        <template #default="scope">
          <span>{{ scope.row.minScore }} ~ {{ scope.row.maxScore }}</span>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <span
            class="option option-primary"
            @click="showEditModal(scope.row)"
            v-auth="this.per.DEPOSIT_UPDATA"
          >
            编辑
          </span>

          <span
            class="option option-primary"
            v-auth="this.per.DEPOSIT_PUT_WAY"
            @click="
              operationProduct(scope.row.id, this.const.PUTWAY_CODE.SOLD_OUT)
            "
          >
            删除
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageNo"
      @current-change="getList"
    ></el-pagination>

    <EditModal
      :modalData="modalData"
      :modalType="modalType"
      :ref="MODAL_KEY.EDIT_MODAL"
      @submit="submit"
    ></EditModal>
  </div>
</template>

<script>
import {
  delVipSetting,
  updateVipSetting,
  AddVipSetting,
  fetchVipList,
} from "@/api/vip";
import EditModal from "./component/EditModal";

export default {
  components: { EditModal },
  data() {
    return {
      dataSource: [{ id: 1 }],
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      query: {}, // 查询条件
      modalData: {},
      modalType: "add",
      MODAL_KEY: {
        EDIT_MODAL: "EDIT_MODAL",
        MAP_MODAL: "MAP_MODAL",
      },
      total: 0,
    };
  },
  methods: {
    //删除
    doDel(id) {
      delVipSetting({
        id: id,
        isOnSale: type,
      }).then((res) => {
        this.$message.success("操作成功");
        this.getList();
      });
    },

    showAdd() {
      this.modalData = {};
      this.modalType = "add";
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },

    showEditModal(row) {
      this.modalData = this.deepClone(row);
      this.modalData.amount = this.util.formatNum(
        this.modalData.amount / 100,
        3
      );
      this.modalType = "edit";
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },

    submit(data) {
      let request = updateVipSetting;
      let msg = "修改成功";
      if (this.validatenull(data.id)) {
        request = AddVipSetting;
        msg = "添加成功";
      }

      request(data).then((res) => {
        this.$message.success(msg);
        this.getList();
        this.$refs[this.MODAL_KEY.EDIT_MODAL].closeModal();
      });
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchVipList({ ...this.query, ...this.pages }).then((res) => {
        this.dataSource = res.data.data.records;
        this.total = res.data.data.total;
      });
    },
  },

  mounted() {
    this.getList();
  },
};
</script>
